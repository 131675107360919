import { ListJson } from './ListJson';
import List from './List';
import { ListRequestJson } from './ListRequestJson';
import ListConfiguration from './configuration/ListConfiguration';
import ListItem from './list-item/ListItem';
import { ListContentTypes } from '../../constants/content-types';
import { slugifyString } from '../../views/Partials/Sidebar/seo-refactored/helpers/slug.helper';

export default class ListBuilder {
	private json: ListJson;

	constructor(list?: List | ListJson) {
		if (list && list instanceof List) {
			this.json = list.toJSON();
		} else if (list) {
			this.json = list;
		} else {
			this.json = {} as ListJson;
		}
	}

	withId(id: string): ListBuilder {
		this.json.id = id;

		return this;
	}

	withTitle(title: string): ListBuilder {
		this.json.title = title;

		return this;
	}

	withCreatedAt(createdAt: string): ListBuilder {
		this.json.createdAt = createdAt;

		return this;
	}

	withUpdatedAt(updatedAt: string): ListBuilder {
		this.json.updatedAt = updatedAt;

		return this;
	}

	withSlug(slug: string): ListBuilder {
		this.json.slug = slug;

		return this;
	}

	withConfiguration(configuration: ListConfiguration): ListBuilder {
		this.json.configuration = configuration;

		return this;
	}

	withItems(items: ListItem[]): ListBuilder {
		this.json.items = items;

		return this;
	}

	withType(type: string): ListBuilder {
		this.json.type = type;

		return this;
	}

	withStatus(status: string): ListBuilder {
		this.json.status = status;

		return this;
	}

	withLockedPositions(lockedPositions: number[]): ListBuilder {
		this.json.lockedPositions = lockedPositions;

		return this;
	}

	withAuthors(authors: string[]): ListBuilder {
		this.json.authors = authors;

		return this;
	}

	withLanguage(language: string): ListBuilder {
		this.json.language = language;

		return this;
	}

	withContentType(contentType: string): ListBuilder {
		this.json.contentType = contentType;

		return this;
	}

	build(): List {
		return List.fromJSON(this.json);
	}

	equals(list: List): boolean {
		const idEqual = this.json.id === list.id;
		const titleEqual = this.json.title === list.title;
		const createdAtEqual = this.json.createdAt === list.createdAt;
		const updatedAttEqual = this.json.updatedAt === list.updatedAt;
		const configurationEqual = ListConfiguration.builder(this.json.configuration).equals(list.configuration);
		const itemsEqual = list && list.items && this.json.items ? this.json.items.length === list.items.length : true;
		const lockPositionsEqual =
			list && list.lockedPositions && this.json.lockedPositions ? this.json.lockedPositions.length === list.lockedPositions.length : true;
		const isTypeEqual = this.json.type === list.type;
		const isStatusEqual = this.json.status === list.status;
		const isContentTypeEqual = this.json.contentType === list.contentType;

		return (
			idEqual &&
			titleEqual &&
			createdAtEqual &&
			updatedAttEqual &&
			configurationEqual &&
			itemsEqual &&
			isTypeEqual &&
			isStatusEqual &&
			lockPositionsEqual &&
			isContentTypeEqual
		);
	}

	remapListItemsForRequestJson(items: any) {
		if (items && items.length > 0) {
			return items
				.filter((el: any) => !!el && !!el.data)
				.map((item: ListItem, index: number) => {
					if (item.type === 'FANS_UNITED') {
						return {
							id: item.data.id,
							data: {
								id: item.data.id,
								description: item.data.description,
								images: item.data.images,
								type: item.data.type,
								title: item.data.title,
							},
							type: item.type,
							weight: index,
							meta: item.meta
								? {
										image_id: item.meta.imageId,
										subtitle: item.meta.subtitle,
										title: item.meta.title,
								  }
								: item.meta,
							from: item.meta.from,
							to: item.meta.to,
						};
					} else {
						return {
							id: item.data.id,
							data: {
								id: item.data.id,
								entity_type: item.data.entity_type,
								title: item.data.title,
								image: item.data.image,
							},
							type: item.type,
							weight: index,
							meta: item.meta
								? {
										image_id: item.meta.imageId,
										subtitle: item.meta.subtitle,
										title: item.meta.title,
								  }
								: item.meta,
							from: item.meta.from,
							to: item.meta.to,
						};
					}
				});
		}
		return items;
	}

	toRequestJson(): ListRequestJson {
		let json = {} as ListRequestJson;
		json.id = this.json.id;
		json.title = this.json.title;
		json.slug = slugifyString(this.json.slug);
		json.configuration = { ...ListConfiguration.builder(this.json.configuration).toRequestJson() };
		json.type = this.json.type ? this.json.type : '';
		json.status = this.json.status ? this.json.status : '';
		json.language = this.json.language ? this.json.language : '';
		if (this.json.contentType !== ListContentTypes.SCHEDULED) {
			json.locked_positions = this.sortedLockedPositions;
		}
		json.items = this.json.items ? this.remapListItemsForRequestJson(this.json.items) : [];
		json.content_type = this.json.contentType;

		return json;
	}

	private get sortedLockedPositions() {
		if (this.json.lockedPositions) {
			return this.json.lockedPositions.sort((a, b) => a - b);
		}

		return [];
	}
}
