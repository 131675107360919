import Article from '../models/article/Article';
import Gallery from '../models/gallery/Gallery';
import Video from '../models/video/Video';
import Wiki from '../models/wiki/Wiki';
import Image from '../models/image/Image';
import ImagoImageModel from '../views/Partials/Blocky/blocks/content/imago-image-block/models/imago-image.model';
import DugoutVideoModel from '../models/dugout-videos/dugout-video/dugout-video.model';
import OnnetworkVideoModel from '../models/onnetwork-videos/onnetwork-video.model';
import AmgItemDataModel from '../views/Partials/Blocky/blocks/content/amg-block/models/amg-item-data.model';
import GettyImageModel from '../views/v2/getty/models/getty-image.model';

export enum ContentTypes {
	ARTICLE = 'article',
	VIDEO = 'video',
	GALLERY = 'gallery',
	BANNER = 'banner',
	LIST = 'list',
	AUTHOR = 'author',
	CATEGORY = 'category',
	POLL = 'poll',
	TAG = 'tag',
	FEED = 'feed',
	TEAM = 'team',
	TOURNAMENT = 'tournament',
	TOURNAMENT_GROUPS = 'tournament_groups',
	VENUE = 'venue',
	COMPETITION = 'competition',
	PRESIDENT = 'president',
	PLAYER = 'player',
	COACH = 'coach',
	ARENA = 'arena',
	QUICK_ACCESS = 'quickAccess',
	IMAGE = 'image',
	PLAYING_SURFACE = 'playingSurface',
	IMAGO_IMAGE = 'imagoImage',
	WIKI_PAGE = 'wikipage',
	GAME = 'game',
	MATCH = 'match',
	SEASON = 'season',
	LIVE_BLOG = 'live_blog',
	EMBED = 'embed',
	MAIN_MEDIA = 'main_media',
	GETTY = 'getty',
	CUSTOM_ENTITIES = 'custom_entities',
	BACKGROUND_IMAGE = 'background_image',
	SHARE_IMAGE = 'share_image',
}

export enum FuContentTypes {
	POLL = 'poll',
	MATCH_QUIZ = 'match_quiz',
	CLASSIC_QUIZ = 'classic_quiz',
}

export enum ListContentTypes {
	EDITORIAL = 'editorial',
	SCHEDULED = 'scheduled',
	METADATA = 'metadata',
}

export const sidebarIcons = {
	general: 'fa fa-cogs',
	media: 'fa fa-image',
	content_footer: 'fa fa-file',
	tags: 'fa fa-tags',
	related_content: 'fa fa-file-text',
	seo: 'fa fa-external-link',
	urls: 'fa fa-search-plus',
	list: 'fa fa-list-ul',
	custom_data: 'fa fa-puzzle-piece',
	translations: 'fa fa-globe',
	live_blogs: 'fa-solid fa-newspaper',
};

export enum ContentMode {
	EDIT = 'edit',
	CREATE = 'create',
}

export enum ContentTypesPluralNaming {
	ARTICLE = 'articles',
	VIDEO = 'videos',
	GALLERY = 'galleries',
	WIKI_PAGE = 'wiki-pages',
}

export type MainContentTypes =
	| ContentTypes.ARTICLE
	| ContentTypes.VIDEO
	| ContentTypes.GALLERY
	| ContentTypes.WIKI_PAGE
	| ContentTypes.IMAGE;
export type EntityTypes = Article | Video | Gallery | Wiki | Image;
export type SelectOptions = {
	label: string;
	value: any;
	data?: any;
	query?: string;
};

export enum MainMediaTypes {
	IMAGO_IMAGE = 'imago_images',
	AMG_VIDEO = 'streamAMG',
	DUGOUT_VIDEO = 'dugoutVideo',
	ONNETWORK_VIDEO = 'onnetworkVideo',
	GETTY_IMAGE = 'getty_images',
}

export type MainMediaContentTypes = ImagoImageModel | DugoutVideoModel | OnnetworkVideoModel | AmgItemDataModel | GettyImageModel;

export type DynamicContentProperty = { name: string; value: boolean; slug: string };

export type DynamicObjectWithKeys = { [x: string]: any };
