import Image from '../../../../../../models/image/Image';
import ListItem from '../../../../../../models/list/list-item/ListItem';
import { cssIconByContentType, svgFuIconByContentType } from '../../list-content.helper';
import { ContentItemModel } from '../ContentItem';
import placeholderImage from '../../../../../../assets/img/image-placeholder.png';
import { extractIds } from '../../../../Articles/Helpers/ArticleHelper';
import { ContentTypes } from '../../../../../../constants/content-types';
import { AuthorJson } from '../../../../../../models/author/AuthorJson';
import { extractMainImageObjectFromArray } from '../../../../../Partials/Sidebar/media-refactored/helpers/general';
import { ContentFuItemModel } from '../FuContentItem';

export const constructContentItemModel = (value: ListItem): ContentItemModel => {
	const image = extractMainImageObjectFromArray(value.data && value.data.main_media);
	const media =
		value.type && value.type.toLowerCase() === ContentTypes.LIVE_BLOG
			? image && getImageSrcOrPlaceholderImage(image.data)
			: getImageSrcOrPlaceholderImage(ListItem.builder(value).getMainImage());
	const date =
		value.type && value.type.toLowerCase() === ContentTypes.LIVE_BLOG
			? value.data && value.data.start_time
			: value.data && value.data.publishedAt;
	return {
		icon: value.type === 'RELATEDCONTENT' ? 'fa fa-medal' : cssIconByContentType[value.type && value.type.toLowerCase()],
		image: value.type === 'RELATEDCONTENT' ? (value.data.image ? value.data.image : placeholderImage) : media,
		publishedAt: date,
		listItemId: value.data && value.data.id,
		title: value.data && value.data.title,
		meta: value.data && value.meta,
		type: value.data && value.type && value.type.toUpperCase(),
		entityType: value.data && value.data.entity_type,
		views: value.data && value.data.viewsCount,
		comments: value.data && value.data.commentsCount,
		authors: value.data && getAuthorNameList(value.data.authors),
		createdBy: value.data && value.data.created_by && value.data.created_by['full_name'],
		subtitle: value.data && value.data.subtitle,
	};
};

export const constructFuContentItemModel = (value: ListItem): ContentFuItemModel => {
	return {
		icon: value.data && value.data.type && svgFuIconByContentType[value.data.type.toLowerCase()],
		image: value.data.images && value.data.images.main ? value.data.images.main : placeholderImage,
		publishedAt: value.data && value.data.created_at,
		listItemId: value.data && value.data.id,
		title: value.data && `[${value.data.title}]`,
		meta: value.data && value.meta,
		type: value.data && value.type && value.type.toUpperCase(),
		entityType: value.data && value.data.type,
		status: value.data && value.data.status,
	};
};

export const getAuthorNameList = (authorsArr: AuthorJson[]) => {
	return (
		authorsArr &&
		authorsArr.map((author: AuthorJson) => {
			return author.name;
		})
	);
};

export const getImageSrcOrPlaceholderImage = (image: Image) => {
	return image && image.urls && image.urls.uploaded && image.urls.uploaded.thumbnail ? image.urls.uploaded.thumbnail : placeholderImage;
};

export const constructContentStatisticsSearchQuery = (content: any) => {
	let articlesQuery = '';
	let videosQuery = '';
	let galleriesQuery = '';
	let liveBlogsQuery = '';
	if (content && content.length > 0) {
		articlesQuery =
			extractContentIdsByType(content, ContentTypes.ARTICLE).length > 0
				? `&articles=${extractContentIdsByType(content, ContentTypes.ARTICLE)}`
				: '';
		videosQuery =
			extractContentIdsByType(content, ContentTypes.VIDEO).length > 0 ? `&videos=${extractContentIdsByType(content, ContentTypes.VIDEO)}` : '';
		galleriesQuery =
			extractContentIdsByType(content, ContentTypes.GALLERY).length > 0
				? `&galleries=${extractContentIdsByType(content, ContentTypes.GALLERY)}`
				: '';
		liveBlogsQuery =
			extractContentIdsByType(content, ContentTypes.LIVE_BLOG).length > 0
				? `&live_blogs=${extractContentIdsByType(content, ContentTypes.LIVE_BLOG)}`
				: '';

		let statisticsQuery = `${articlesQuery}${videosQuery}${galleriesQuery}${liveBlogsQuery}`.substring(1);
		return `?${statisticsQuery}`;
	}

	return '';
};

export const extractContentIdsByType = (content: any, type: string) => {
	switch (type) {
		case ContentTypes.ARTICLE: {
			return extractIds(extractContentByType(content, ContentTypes.ARTICLE.toUpperCase()));
		}
		case ContentTypes.VIDEO: {
			return extractIds(extractContentByType(content, ContentTypes.VIDEO.toUpperCase()));
		}
		case ContentTypes.GALLERY: {
			return extractIds(extractContentByType(content, ContentTypes.GALLERY.toUpperCase()));
		}
		case ContentTypes.LIVE_BLOG: {
			return extractIds(extractContentByType(content, ContentTypes.LIVE_BLOG.toUpperCase()));
		}

		default:
			return [];
	}
};

export const extractContentByType = (content: any, type: string) => {
	if (content && content.length > 0) {
		return content.filter((item: any) => item.type === type).map((item: any) => item.data);
	}
	return [];
};

export const reconstructContentForStatistics = (items: any) => {
	if (items && items.length > 0) {
		return items.map((item: any) => ({ data: item.content, type: item.type }));
	}
	return [];
};
